export type SignUpPromoCode = string
type HomepageDiscountBannerText = string
type PromocodeDescriptionLines = string[]
export type OfxTreatmentId = string
export type OfxExperimentName = string
export type PromoCodeVariant = string
export type ExperimentVersion = 'homepage' | 'BPPC'

export interface PromoCodeData {
  defaultPromoCode: SignUpPromoCode
  defaultBannerText: HomepageDiscountBannerText
  defaultDescriptionLines: PromocodeDescriptionLines
}

export interface PromoCodeMappings {
  [key: OfxExperimentName]: {
    [version in ExperimentVersion]: {
      [key: OfxTreatmentId]: PromoCodeData
    }
  }
}

export type promoCodeMappingsToExperiments = Record<
  SignUpPromoCode,
  [OfxExperimentName, ExperimentVersion]
>

// Constants
export const hero = {
  header: 'Mouthwatering home-cooked meals',
  subheader: 'Over 250 recipes a month from just £2.99 a meal*',
  benefits: '85+ veggie (incl vegan) recipes a month',
}

export const PROMO_CODE_DATA: PromoCodeData = {
  defaultPromoCode: 'DTIHPST5040C',
  defaultBannerText: 'Get 50% off your first box and more discounts on your boxes for 2 months!†',
  defaultDescriptionLines: [
    'Get 50% off your first order, 40% off your second order and 20% off all other orders in your first two months.',
    "Click 'Claim discount' below and your voucher will be automatically applied at checkout.",
  ],
}

export const DISCOUNT_SOFTENING_PROMO_CODE_DATA_V3: PromoCodeData = {
  defaultPromoCode: 'DTIHPST5040CFD',
  defaultBannerText: '50% off first two boxes + 20% off your next six!†',
  defaultDescriptionLines: [
    'Get 50% off your first order, 40% off your second order and 20% off all other orders in your first two months.',
    "Click 'Claim discount' below and your voucher will be automatically applied at checkout.",
  ],
}

// c is the control for no free delivery experiment and old UI for traffic landing from the PPC brand
export const BRAND_PPC_PROMO_CODE_DATA: PromoCodeData = {
  defaultPromoCode: 'GB-SEABRST5040',
  defaultBannerText: 'Get 50% off your first box and more discounts on your boxes for 2 months!†',
  defaultDescriptionLines: [
    'Get 50% off your first order, 40% off your second order and 20% off all other orders in your first two months.',
    "Click 'Claim discount' below and your voucher will be automatically applied at checkout.",
  ],
}

// t1 is the treatment for no free delivery experiment and new UI for default
export const DISCOUNT_SOFTENING_UI_PROMO_CODE_DATA: PromoCodeData = {
  defaultPromoCode: 'DTIHPST5040N',
  defaultBannerText: 'Get 50% off your first box and more discounts on your boxes for 2 months!†',
  defaultDescriptionLines: [
    '✔️ 50% off your first box*',
    '✔️ 40% off your second box*',
    '✔️ 20% off the next six boxes*',
    '*Offer expires two months after sign up',
  ],
}

// t1 is the treatment for no free delivery experiment and new UI for traffic landing from the PPC brand
export const BRAND_PPC_DISCOUNT_SOFTENING_UI_PROMO_CODE_DATA: PromoCodeData = {
  defaultPromoCode: 'GB-SEABRST5040N',
  defaultBannerText: 'Get 50% off your first box and more discounts on your boxes for 2 months!',
  defaultDescriptionLines: [
    '✔️ 50% off your first box*',
    '✔️ 40% off your second box*',
    '✔️ 20% off the next six boxes*',
    '*Offer expires two months after sign up',
  ],
}

// t2 is the treatment for free delivery experiment / old UI for default
export const FREE_DELIVERY_PROMO_CODE_DATA: PromoCodeData = {
  defaultPromoCode: 'DTIHPST5040CFD',
  defaultBannerText:
    'Get 50% off your first box and more discounts on your boxes for 2 months - plus FREE delivery for a year!*',
  defaultDescriptionLines: [
    'Get 50% off your first order, 40% off your second order and 20% off all other orders in your first two months.* Plus get FREE delivery for a year, worth up to £200.',
    "Click 'Claim discount' below and your voucher will be automatically applied at checkout.",
    'Happy cooking!',
    '*Offer expires two months after sign up',
  ],
}

// t2 is the treatment for free delivery experiment / old UI for traffic landing from the PPC brand
export const BRAND_PPC_FREE_DELIVERY_PROMO_CODE_DATA: PromoCodeData = {
  defaultPromoCode: 'GB-SEABRST5040CFD',
  defaultBannerText:
    'Get 50% off your first box and more discounts on your boxes for 2 months - plus FREE delivery for a year!*',
  defaultDescriptionLines: [
    'Get 50% off your first order, 40% off your second order and 20% off all other orders in your first two months.* Plus get FREE delivery for a year, worth up to £200.',
    "Click 'Claim discount' below and your voucher will be automatically applied at checkout.",
    'Happy cooking!',
    '*Offer expires two months after sign up',
  ],
}

// t3 is the treatment for the discount softening free delivery experiment for default
export const DISCOUNT_SOFTENING_UI_WITH_FREE_DELIVERY_PROMO_CODE_DATA: PromoCodeData = {
  defaultPromoCode: 'DTIHPST5040NFD',
  defaultBannerText:
    'Get 50% off your first box and more discounts on your boxes for 2 months - plus FREE delivery for a year!*',
  defaultDescriptionLines: [
    '✔️ 50% off your first box*',
    '✔️ 40% off your second box*',
    '✔️ 20% off the next six boxes*',
    '✔️ FREE delivery for a year, worth up to £200',
    '*Offer expires two months after sign up',
  ],
}

// t3 is the treatment for the discount softening free delivery experiment for traffic landing from the PPC brand
export const BRAND_PPC_DISCOUNT_SOFTENING_UI_WITH_FREE_DELIVERY_PROMO_CODE_DATA: PromoCodeData = {
  defaultPromoCode: 'GB-SEABRST5040NFD',
  defaultBannerText:
    'Get 50% off your first box and more discounts on your boxes for 2 months - plus FREE delivery for a year!',
  defaultDescriptionLines: [
    '✔️ 50% off your first box*',
    '✔️ 40% off your second box*',
    '✔️ 20% off the next six boxes*',
    '✔️ FREE delivery for a year, worth up to £200',
    '*Offer expires two months after sign up',
  ],
}

export const GIFTING_EXPERIMENT_PROMO_CODE_DATA: PromoCodeData = {
  defaultPromoCode: 'DTI-HP-GFT60',
  defaultBannerText:
    'Get 60% off your first order plus 20% off for two months and FREE DESSERT for three months**',
  defaultDescriptionLines: [
    `Get 60% off your first order!`,
    `Then 20% off all other boxes you order in your first two months and FREE DESSERT for three months.`,
  ],
}

export const RAF_LANDING_PAGE_EXPERIMENT_PROMO_CODE_DATA: PromoCodeData = {
  defaultPromoCode: 'DTI-SB-602020',
  defaultBannerText: 'Get 65% off your first order and 25% off your first two months',
  defaultDescriptionLines: [
    `Get 65% off your first order!`,
    `Then 25% off all other boxes you order in your first two months.`,
  ],
}

// This is a Brand PPC version of the discount with a free gift
export const BPPC_GIFTING_EXPERIMENT_PROMO_CODE_DATA = {
  defaultPromoCode: 'GB-DINNER-SEA-BR-GFT-6020',
}

export const knownVariants = ['default', 'tv']

export const defaultVariant = 'default'

export const seo = {
  title: 'Recipe Boxes | Get Fresh Food & Recipes Delivered | Gousto',
  meta: [
    {
      name: 'description',
      content:
        'Change the way you eat with our easy to follow recipes. We deliver fresh boxes of ingredients and delicious recipes 7 days a week. Get started now!',
    },
    {
      name: 'keywords',
      content: 'Gousto, recipe delivery, ingredients, fresh, healthy food, cooking, recipe box',
    },
  ],
}

// Promocode Experiment mappings
export const PROMO_CODE_OFX_MAPPINGS: PromoCodeMappings = {
  beetroots_ofx_discount_softening_free_delivery_v2: {
    homepage: {
      t1: DISCOUNT_SOFTENING_UI_PROMO_CODE_DATA,
      t2: FREE_DELIVERY_PROMO_CODE_DATA,
      t3: DISCOUNT_SOFTENING_UI_WITH_FREE_DELIVERY_PROMO_CODE_DATA,
    },
    // [BEET-417] Update BPPC allocation: remap t2 to 'c' and t3 to 't1' to reflect cancellation of free delivery
    BPPC: {
      t1: BRAND_PPC_DISCOUNT_SOFTENING_UI_PROMO_CODE_DATA,
      t2: BRAND_PPC_PROMO_CODE_DATA,
      t3: BRAND_PPC_DISCOUNT_SOFTENING_UI_PROMO_CODE_DATA,
    },
  },
}

// Customers arriving with one of the following promo codes will be assigned to an OFX experiment
export const PROMO_CODES_MAPPED_TO_EXPERIMENTS: promoCodeMappingsToExperiments = {
  [BRAND_PPC_PROMO_CODE_DATA.defaultPromoCode]: [
    'beetroots_ofx_discount_softening_free_delivery_v2',
    'BPPC',
  ],
}

export const homeConfig = {
  hero,
  PROMO_CODE_DATA,
  knownVariants,
  defaultVariant,
  seo,
}
