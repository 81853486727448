import { ThunkExperiments } from '@library/experimentation'

import { actionTypes } from 'actions/actionTypes'
import { promoChange, promoToggleModalVisibility } from 'actions/promos'
import { redirect } from 'actions/redirect'
import { clickGetStarted } from 'actions/trackingKeys'
import { DISCOUNT_SOFTENING_PROMO_CODE_DATA_V3, PROMO_CODE_DATA } from 'routes/Home/homeConfig'
import { getPromoBannerState } from 'routes/Home/homeUtils'
import { ThunkActionCreator } from 'routes/Home/types/ThunkActionCreator'
import { getIsAuthenticated } from 'selectors/auth'
import { getUTMAndPromoCode } from 'selectors/tracking'
import logger from 'utils/logger'

import { hasConsentForAnalytics } from '../hooks/useGetDiscountSofteningFreeDelivery'

export const applyPromoCodeAndShowModal: ThunkActionCreator =
  () =>
  async (dispatch, getState, { experiments }: ThunkExperiments) => {
    const state = getState()
    const isAuthenticated = getIsAuthenticated(state)
    const hasAcceptedCookies = hasConsentForAnalytics()
    const shouldBeAllocatedToExperiment = !isAuthenticated && hasAcceptedCookies
    const { canApplyPromo } = getPromoBannerState(state)
    if (!canApplyPromo) {
      return
    }

    const { enabled, variationKey } =
      experiments && shouldBeAllocatedToExperiment
        ? experiments.decide('beetroots_ofx_discount_softening_v3')
        : { enabled: false, variationKey: undefined }

    const isDiscountSofteningV3Enabled = enabled && variationKey === 't1'

    const promoCode = isDiscountSofteningV3Enabled
      ? DISCOUNT_SOFTENING_PROMO_CODE_DATA_V3.defaultPromoCode
      : PROMO_CODE_DATA.defaultPromoCode

    try {
      await dispatch(promoChange(promoCode))
    } catch (err) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      logger.warning(`error fetching promo code ${promoCode} - ${(err as Error).message}`, err)

      return
    }
    dispatch(promoToggleModalVisibility(true))
  }

export const trackGetStarted: ThunkActionCreator =
  (trackingSectionName: string) =>
  (dispatch, getState, { experiments }) => {
    const { promoCode, UTM } = getUTMAndPromoCode(getState())
    experiments.track('start_onboarding')
    dispatch({
      type: actionTypes.TRACKING,
      trackingData: {
        actionType: clickGetStarted,
        section: trackingSectionName,
        ...UTM,
        promoCode,
      },
    })
  }

export const homeGetStarted: ThunkActionCreator =
  (ctaUri: string, sectionForTracking?: string) => async (dispatch) => {
    if (sectionForTracking) {
      dispatch(trackGetStarted(sectionForTracking))
    }

    await dispatch(applyPromoCodeAndShowModal())

    dispatch(redirect(ctaUri))
  }
